<div class="login-page">
    <!-- Top-left corner text -->
    <div class="top-left-corner">
        <p>NorthStandard Account</p>
    </div>

    <!-- Main content -->
    <div class="forgot-password-page central-column-page">

        <div class="forgot-password-page-wrapper">
            <div title="NorthStandard" class="company-logo" tabindex="-1">
                <img src="assets/images/Combined_shape.png">
            </div>
            <h1>Forgot your password?</h1>

            <p class="success-message">To reset your password, please enter your email address.</p>
            <!-- <form (keydown.enter)="$event.preventDefault()" [formGroup]="forgotPasswordForm" #fform="ngForm"
                (ngSubmit)="onSubmit()">
                <div class="input">
                    <label>Email</label>
                    <input type="text" formControlName="email" id="email">
                    <div class="form-text error text-danger" *ngIf="formErrors.email">
                        {{formErrors.email}}
                    </div>
                </div>
                <div class="forget-pass-cont">
                    <p>You will receive a password reset link by email</p>
                </div>
                <div class="forget-btns">
                    <button class="cursor-pointer yes-btn" [disabled]="forgotPasswordForm.invalid" type="submit"
                        [ngClass]="forgotPasswordForm.invalid ? 'btn-disabled' : ''">Yes</button>
                    <button class="no-btn" type="button" [routerLink]="['/']">No</button>
                </div>
            </form> -->
            <form id="forgot-password-form" novalidate="true" (keydown.enter)="$event.preventDefault()"
                [formGroup]="forgotPasswordForm" #fform="ngForm" (ngSubmit)="onSubmit()" class="form-group"
                [ngClass]="{'error': formErrors.email}">
                <p>
                    <label for="EmailAddress">Email (required)</label>
                    <input type="email" formControlName="email" id="email" placeholder="Enter email address here..."
                        required="" maxlength="256" autofocus="" data-val="true"
                        data-val-email="Your email address does not look valid, please try again" value="">
                    <!-- <span class="error-message" *ngIf="formErrors.email">
                        {{formErrors.email}}
                        Please check your email address, it does not look correct.
                    </span> -->
                </p>
                <div class="error-message" *ngIf="formErrors.email">{{ formErrors.email }}</div>
                <p>
                    <button id="forgot-password-btn" [disabled]="forgotPasswordForm.invalid" type="submit"
                        [ngClass]="forgotPasswordForm.invalid ? 'btn-disabled' : ''"
                        data-requires-valid="#EmailAddress">Continue</button>
                </p>
            </form>
        </div>
    </div>

    <footer class="page-footer footer-variation-default " title="Page footer">
        <p class="footer-copywrite-msg">© 2024 NorthStandard</p>
        <p class="footer-build-msg">Build <span>1.2.0.20242 (UAT)</span></p>
        <a href="https://north-standard.com" class="footer-company-logo" title="Visit north-standard.com"></a>
    </footer>
</div>