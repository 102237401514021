import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoggedInAuthGuard } from '@shared/guard/loggedin-auth-guard';
import { AuthComponent } from './auth.component';

const routes: Routes = [
  {
    path: '',
    component: AuthComponent,
    children: [
      { path: 'maintenance', loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule) },
      {
        path: '',
        loadChildren: () =>
          import('./login/login.module').then(m => m.LoginModule),
        canActivate: [LoggedInAuthGuard],
      },

      {
        path: 'forgot-password',
        loadChildren: () =>
          import('./forgot-password/forgot-password.module').then(
            m => m.ForgotPasswordModule
          ),
      },
      {
        path: 'reset-password/:token',
        loadChildren: () =>
          import('./reset-password/reset-password.module').then(
            m => m.ResetPasswordModule
          ),
      },
      {
        path: 'set-password/:token',
        loadChildren: () =>
          import('./set-password/set-password.module').then(
            m => m.SetPasswordModule
          ),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [LoggedInAuthGuard],
})
export class AuthRoutingModule {}
